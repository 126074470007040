body{
    /* background-image: url('../Assets/body-img1.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.nav-about{
    /* background-image: url('../Assets/nav-img5.jpg'); */
 /* height:60%; */
    border: 1px solid rgb(210, 207, 207);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:90%
    /* filter: blur(8px) */
    
}
.community{
    width:93%;
    margin:15% 6%;
    border-radius: 7px;
}
.aboutus1{
margin: auto;
padding:2%;
width:85%;
background-color:rgb(207, 205, 194);
border-radius:10px;
box-shadow:2px 2px rgb(161, 156, 156)
}
li{
    /* line-height:2; */
    font-size: 19px;
}
.trustees{
margin: 2% auto;
padding:6%;
width:85%;
background-color:rgb(206, 197, 202);
border-radius:10px;
box-shadow:2px 2px rgb(161, 156, 156);
height:10%;
}
.proverb{
    text-align:left;
    margin-bottom:3%;
    font-size: 16px;
    color:rgb(35, 88, 88)
}
h1{
    text-align:center;
    margin-bottom:3%;
    font-size: 32px;
}
.mypix{
    width:280px;
    height:230px;
    border-radius:50%;
    border:5px solid rgb(170, 163, 123);
    
}
.membergrid{
    border:1px solid rgb(134, 125, 125);
    padding-left: 25%;
    padding-top: 10%;
    border-radius:5px;
    box-shadow:2px 2px rgb(134, 125, 125);
    background-color:rgb(207, 205, 194)
    
}
.mypix1{
    width:150px;
    height:100px;
    border-radius:50%;
}
.nametag{
    /* border:5px solid; */
    text-align:center;
    width:70%;
    font-family:cursive;
    color:rgb(114, 48, 23)

}
@media only screen and (max-width: 570px) {
    .mypix{
        width:200px;
        height:200px;
        border-radius:50%;
        border:5px solid rgb(170, 163, 123);
    }
}

@media only screen and (max-width: 600px) {
    .community{
        width:93%;
        margin:10% 6%;
        border-radius: 7px; 
    }
    .nametag{
        /* border:1px solid; */
        text-align:center;
        width:70%;
        margin:10% 10%;
        font-size: 16px;
        font-weight:bold;
        font-family:'Courier New', Courier, monospace
    }
}
@media only screen and (max-width: 640px) {
    .community{
        width:90%;
        margin:8% 6%;
        border-radius: 7px;
    } 
    .nametag{
        /* border:1px solid; */
        text-align:center;
        width:70%;
        font-size: 16px;
        font-weight:bold;
        font-family:cursive

    }

}
@media only screen and (max-width: 670px) {
    .mypix{
        width:220px;
        height:220px;
        border-radius:50%;
        border:5px solid rgb(170, 163, 123);
    margin-left: 2%;

    }
}

@media only screen and (max-width: 720px) {
.mypix{
    /* margin-left: 20%; */
}
}




