body{
    box-sizing: border-box;
}


.humanservices{
    margin:auto;
    border-radius: 7px;
    width:90%;
    margin-bottom:5%
}
.vid1{
    width:85%;
    margin:0 6%;
    border-radius: 10px;
}
.vid-text{
width:90%

}
h2{
    color:rgb(35, 88, 88);
    font-size:16px
}
h3{
    font-family: cursive;
    text-align: center;
    margin: 20% auto;
    font-size:18px
}
.activities{
    margin: 2% auto;
    padding:6%;
    width:85%;
    background-color:rgb(176, 175, 165);
    border-radius:10px;
    box-shadow:2px 2px rgb(161, 156, 156);
    height:10%;
    }
@media only screen and (max-width: 600px) {
    body{
        width:90vw;
        padding:0;
        margin:0;
        box-shadow: border-box;
    }
    .humanservices{
        margin:auto;
        border-radius: 7px;
        width:90%;
        margin-bottom:5%
    }
    .vid1{
        width:20px;
    margin:10% auto;
    /* border:7px solid */
    }
    .vid-cont{
        /* border:15px solid red; */
        width:280px;
        margin:auto;

    }
    .vid-cont iframe{
   width:270px
    }
}


