.container{
    display:flex;
justify-content:space-between;
}
.contact-det{
    text-align:center;
    padding: 5%;
}
h2, h3{
    margin-bottom:20px

}
.phone-grid{
padding:30px;
width:60%;

}
.phone{
    height:80%;
    width:60%;
    border-radius:7px;
}
.icon{
    position: relative;
    top:16px;
}

.contact-inv{
    font-size: 1.5rem;
    color:rgb(67, 106, 67);

}

.email-inv{
    color:rgb(114, 48, 23);
    font-size: 1.5rem;
}
.email{
    text-decoration: none;
    background-color:transparent;
    border: none;
    outline: none;
    font-size: 1.3rem;
    color:rgb(89, 0, 255)
}
.email-btn{
    text-decoration: none;
    background-color:transparent;
    border: none;
    outline: none;
    font-size: 1.3rem;
    color:rgb(207, 102, 57)
}

@media only screen and (max-width: 640px) {
    .container{
        /* padding:20px; */
        /* border:1px solid; */
        margin-top:20%
    }
    .contact-inv{
        font-size:16px;
        /* border:1px solid red; */
        margin-right:5%
    }
    .phone-grid{
        padding:0
    }
    .contact-det {
        text-align: center;
        padding: 3%;
    }
    .h3 {
        font-family: cursive;
        text-align: center;
        margin: 20% auto;
        font-size: 16px;
    }
    .email{
        font-size:16px;
    }
    .email-inv{
        font-size:14px;
    }
    .email-btn{
        font-size:16px;
    }
}
@media only screen and (max-width: 812px) {
 .phone-grid{
    display: none;
 }
}


/* @media only screen and (max-width: 640px) {
    body{
      box-sizing: border-box;
    }
    .container{
        display:flex;
    justify-content:space-between;
    }
    .contact{
        text-align:center;
        margin-top:20%
    }

    .contact-inv{
        font-size: 16px;
        margin-left: 0;

    }
    
    .email-inv{
        color:rgb(114, 48, 23);
        font-size: 1.5rem;
    }
} */

