body{
box-sizing:border-box;
/* overflow:hidden */
}

.carouse-sec{
    margin-top:30px;
}
.landing-title{
    position:relative;
    /* color:white; */
    color:rgb(35, 88, 88);
    font-family: cursive;
    text-align:center;
    font-size:28px;
    top:20%;
    /* border:1px solid */
}
.logo-div{
    /* border:1px solid; */
    display: flex;
    justify-content:space-between;
    width:100%;
}
.landing-img{
    width:100%;
    margin:0% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border:1px solid; */
    /* height:50%; */

}
.home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
    @media only screen and (max-width: 650px) {
        .landing-img{
            width:98%;
            margin:7% 5%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            /* border:5px solid; */
        }
    }
@media only screen and (max-width: 590px) {
    .landing-img{
        width:150%;
        margin:7% 7%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }  
}
@media only screen and (max-width: 600px) {
    body{
    box-sizing: border-box;
    margin:0;
    padding:0;

    }
    .landing-img{
        width:102%;
        margin:7% 6%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
    .landing-title{
        position:relative;
        /* color:white; */
        color:rgb(35, 88, 88);
        font-family: cursive;
        text-align:center;
        font-size:16px;
        font-weight:bold;
        top:20%;
        /* border:1px solid */
    }
    .activity{
        /* border:1px solid; */
        margin-bottom:45%
    }

}
@media only screen and (max-width: 250px) {
 
    .landing-img{
        width:110%;
        margin:8% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media only screen and (max-width: 800px) {
    body{
        box-sizing: border-box;
        margin:0;
        padding:0;
    
        }
    .landing-img{
        width:103%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}
@media only screen and (max-width: 850px) {
    .landing-img{
        width:100%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}

@media only screen and (max-width: 1400px) {
    .landing-img{
        width:109%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}
