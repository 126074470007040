body{
    width:100%;
    box-sizing: border-box
}
.res-img{
    /* background-image: url('../Assets/lioninthewild.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:20%;
    width:99%;
    border:5px solid;
    margin:0 auto
}
.img-const{
    /* border:7px solid red; */
    padding:0 auto;
    height:40%;

}
.res-sec{
    
    background-color:rgb(191, 181, 144);
    height:70%;
    border:1px solid rgb(191, 181, 144);
    padding-top:5%;
    margin-bottom:5%
}
.resources{
    margin:auto;
    border-radius: 7px;
    width:90%;
    margin-bottom:5%;
    /* border:5px solid; */
}
.res-grid{
    display:flex;
    justify-content:space-between;
    /* border:1px solid red; */
    padding:10% auto;
    height:50%;
    
}
.const-grid{
    background-color:rgb(207, 205, 194);
    width:30%;
   border-radius: 12px;
    

}
.const-grid2{
    background-color:rgb(207, 205, 194);
    width:30%;
   border-radius: 12px;
    

}
.text-div{
    /* border:1px solid; */
    padding:5% 10%
}
.text-div2{
    /* border:1px solid; */
    padding:5% 10%;
    text-align:center;
}
.img-const{
    margin-left:15%;
    margin-top:10%;
    width:68%;
    border-radius:7px;
    outline:5px solid rgb(180, 162, 64)
}
.picnic-grid{
    margin-bottom:10%;
}
.resgrid{
    border:1px solid rgb(134, 125, 125);
    padding-left: 25%;
    padding-top: 7%;
    border-radius:5px;
    box-shadow:2px 2px rgb(134, 125, 125);
    background-color:rgb(207, 205, 194)
    
}
.mypix2{
    width:70%;
    height:50%;
    border-radius:7%;
}
.button-div{
    /* border:1px solid black; */
    display: flex;
    justify-content:center;
    height:70px;
    border-radius:7px;
}
button{
    background-color:rgb(114, 48, 23);
    color:white;
    height:70px;
    border-radius:7px;

}

@media only screen and (max-width: 600px) {
.res-sec{
    margin-top:15%
}
.res-grid{
    display:block;
    width:100%;
}
    .const-grid{
        width:100%;
    }
    .const-grid2{
        width:100%;
        margin-top:5%;
    }
    .middle-div{
        margin-top:5%;
        border:1px solid 
    }
}
