a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.navbar{
display: flex;
justify-content:space-between;
/* border:2px solid; */
height:10px;
margin-top:2%;
margin-bottom:5%;
}
.logo-img{
    height:20%;
}
.navbar1{
    display:flex;
    justify-content:space-between;
    height:100px;
    background-color:transparent;
    padding-right:7%;
    padding-top:15px;
    /* border:1px solid; */
    width:50%;

    /* margin-top:5% */
}
/* .logo-img{
    padding-top:15px;
    width:20%;
    height:50%;
    padding-right:5%;
    border-radius:50%;
} */
.logo-div{
    /* border:1px solid; */
   width: 35%;
    margin-right:5%;
    text-decoration:none;

}
.nav-button{
    background-color:transparent;
    outline:none;
    border:none;
    width:100%;
    cursor: pointer;
    font-family:Arial;
    font-size:16px;
    font-weight:bold;
    color:rgb(35, 88, 88);
    text-decoration: none;
    /* border:1px solid */
    
}
.nav-button1{
    background-color:transparent;
    outline:none;
    border:none;
    width:100%;
    cursor: pointer;
    font-family:Arial;
    font-size:16px;
    font-weight:bold;
    color:rgb(35, 88, 88);
    text-decoration:none;
    
}
.nav-button:hover{
    color:rgb(198, 180, 99)
    
}
.nav-button1:hover{
    color:rgb(198, 180, 99)
    
}
.nava-logo{
    width:50px;
    height:50px;
}
@media only screen and (max-width: 590px) {
  
    .nav-button1{
        display:none;
    }
    .navbar {
        width:100%;
    }
    .navbar1 {
        width:90%;
        margin:auto;
      }
      .nav-button{
          font-size:10px;
          width:80px
    }
    .logo-div{
        display: none;
        position: relative;
        left: 56%;
        top: 129%;
    }
    
    .home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
}

@media only screen and (max-width: 600px) {
  
    .nav-button1{
        display:none;
    }
    .navbar {
        width:100%;
    }
    .navbar1 {
        width:60%;
        margin:auto;
      }
      .nav-button{
          font-size:12px;
          width:80px
    }
    .logo-div{
        display: none;
        position: relative;
        left: 56%;
        top: 129%;
        /* border:1px solid  */
    }
    
    .home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
}
@media only screen and (max-width: 650px) {
    .navbar{
        display: flex;
        justify-content:space-between;
        /* border:2px solid; */
        height:10px;
        margin-top:5%;
        margin-bottom:8%;
        margin-left:-4%

        } 
        .nava-logo{
            width:40px;
            height:40px;
        }
        .navbar1 {
            width:80%;
            margin:auto;
          }
          .nav-button{
              font-size:12px;
              width:50px;
            /* border:1px solid */
        }
        .logo-div{
            display: none;
            position: relative;
            left: 56%;
            top: 129%;
            /* border:1px solid  */
        }
}
@media only screen and (max-width: 750px) {
    .navbar{
        display: flex;
        justify-content:space-between;
        /* border:2px solid; */
        height:10px;
        margin-top:2%;
        margin-bottom:8%;

        } 
        .nava-logo{
            width:40px;
            height:40px;
        }
        .navbar1 {
            width:100%;
            margin:auto;
          }
          .nav-button{
              font-size:10px;
              width:80px
        }

}
