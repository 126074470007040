body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.navbar{
display: flex;
justify-content:space-between;
/* border:2px solid; */
height:10px;
margin-top:2%;
margin-bottom:5%;
}
.logo-img{
    height:20%;
}
.navbar1{
    display:flex;
    justify-content:space-between;
    height:100px;
    background-color:transparent;
    padding-right:7%;
    padding-top:15px;
    /* border:1px solid; */
    width:50%;

    /* margin-top:5% */
}
/* .logo-img{
    padding-top:15px;
    width:20%;
    height:50%;
    padding-right:5%;
    border-radius:50%;
} */
.logo-div{
    /* border:1px solid; */
   width: 35%;
    margin-right:5%;
    text-decoration:none;

}
.nav-button{
    background-color:transparent;
    outline:none;
    border:none;
    width:100%;
    cursor: pointer;
    font-family:Arial;
    font-size:16px;
    font-weight:bold;
    color:rgb(35, 88, 88);
    text-decoration: none;
    /* border:1px solid */
    
}
.nav-button1{
    background-color:transparent;
    outline:none;
    border:none;
    width:100%;
    cursor: pointer;
    font-family:Arial;
    font-size:16px;
    font-weight:bold;
    color:rgb(35, 88, 88);
    text-decoration:none;
    
}
.nav-button:hover{
    color:rgb(198, 180, 99)
    
}
.nav-button1:hover{
    color:rgb(198, 180, 99)
    
}
.nava-logo{
    width:50px;
    height:50px;
}
@media only screen and (max-width: 590px) {
  
    .nav-button1{
        display:none;
    }
    .navbar {
        width:100%;
    }
    .navbar1 {
        width:90%;
        margin:auto;
      }
      .nav-button{
          font-size:10px;
          width:80px
    }
    .logo-div{
        display: none;
        position: relative;
        left: 56%;
        top: 129%;
    }
    
    .home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
}

@media only screen and (max-width: 600px) {
  
    .nav-button1{
        display:none;
    }
    .navbar {
        width:100%;
    }
    .navbar1 {
        width:60%;
        margin:auto;
      }
      .nav-button{
          font-size:12px;
          width:80px
    }
    .logo-div{
        display: none;
        position: relative;
        left: 56%;
        top: 129%;
        /* border:1px solid  */
    }
    
    .home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
}
@media only screen and (max-width: 650px) {
    .navbar{
        display: flex;
        justify-content:space-between;
        /* border:2px solid; */
        height:10px;
        margin-top:5%;
        margin-bottom:8%;
        margin-left:-4%

        } 
        .nava-logo{
            width:40px;
            height:40px;
        }
        .navbar1 {
            width:80%;
            margin:auto;
          }
          .nav-button{
              font-size:12px;
              width:50px;
            /* border:1px solid */
        }
        .logo-div{
            display: none;
            position: relative;
            left: 56%;
            top: 129%;
            /* border:1px solid  */
        }
}
@media only screen and (max-width: 750px) {
    .navbar{
        display: flex;
        justify-content:space-between;
        /* border:2px solid; */
        height:10px;
        margin-top:2%;
        margin-bottom:8%;

        } 
        .nava-logo{
            width:40px;
            height:40px;
        }
        .navbar1 {
            width:100%;
            margin:auto;
          }
          .nav-button{
              font-size:10px;
              width:80px
        }

}

body{
box-sizing:border-box;
/* overflow:hidden */
}

.carouse-sec{
    margin-top:30px;
}
.landing-title{
    position:relative;
    /* color:white; */
    color:rgb(35, 88, 88);
    font-family: cursive;
    text-align:center;
    font-size:28px;
    top:20%;
    /* border:1px solid */
}
.logo-div{
    /* border:1px solid; */
    display: flex;
    justify-content:space-between;
    width:100%;
}
.landing-img{
    width:100%;
    margin:0% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border:1px solid; */
    /* height:50%; */

}
.home-logo{
    color:rgb(35, 88, 88);
    }
    .home-logo:hover{
    color:rgb(198, 180, 99)

    }
    @media only screen and (max-width: 650px) {
        .landing-img{
            width:98%;
            margin:7% 5%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            /* border:5px solid; */
        }
    }
@media only screen and (max-width: 590px) {
    .landing-img{
        width:150%;
        margin:7% 7%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }  
}
@media only screen and (max-width: 600px) {
    body{
    box-sizing: border-box;
    margin:0;
    padding:0;

    }
    .landing-img{
        width:102%;
        margin:7% 6%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
    .landing-title{
        position:relative;
        /* color:white; */
        color:rgb(35, 88, 88);
        font-family: cursive;
        text-align:center;
        font-size:16px;
        font-weight:bold;
        top:20%;
        /* border:1px solid */
    }
    .activity{
        /* border:1px solid; */
        margin-bottom:45%
    }

}
@media only screen and (max-width: 250px) {
 
    .landing-img{
        width:110%;
        margin:8% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media only screen and (max-width: 800px) {
    body{
        box-sizing: border-box;
        margin:0;
        padding:0;
    
        }
    .landing-img{
        width:103%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}
@media only screen and (max-width: 850px) {
    .landing-img{
        width:100%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}

@media only screen and (max-width: 1400px) {
    .landing-img{
        width:109%;
        margin:7% 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* border:1px solid; */
    }
}

body{
    box-sizing: border-box;
    /* top:0 */
}
.footer-container{
    background-color: #aba8a8;
    margin-bottom:0;
    /* width: 120%;? */
    
    
    display:flex;
    justify-content:space-around;
}

.icon{
    align-items:center;
}
.icons{
    margin-left:20%
}
.email{
    color: black;
    text-align:center
}
@media only screen and (max-width: 590px) {
    .footer-container{
        background-color: #aba8a8;
        margin:0% 0%;
        Padding:10% 0%;
        height:40%;
        display:block;
        width:120%;
        justify-content:space-around;
        /* border:1px solid rgb(128, 87, 0); */
        overflow:hidden;
    
    }
}
@media only screen and (max-width: 600px) {
.footer-container{
    background-color: #aba8a8;
    margin:0% 0%;
    Padding:10% 0%;
    height:40%;
    display:block;
    width:100%;
    justify-content:space-around;
    /* border:1px solid green; */
    overflow:hidden;

}
.icon{
    align-items:center;
    margin:auto;
    width:40%;
    font-size:12px;
    text-align:center

}
.icons{
    margin:auto;
    font-size:12px;
    color:blue

}
.email h4{
    text-align:center;
    font-size:10px;
    color:black;

}
}
@media only screen and (max-width: 680px) {
    .footer-container{
        background-color: #aba8a8;
        position: relative;
        top:-20;
        height:40%;
        margin-bottom:0;
        display:block;
        width:99%;
        justify-content:space-around;
        /* border:1px solid rgb(115, 0, 128); */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 800px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        height:40%;
        margin-bottom:0;
        display:block;
        width:103%;
        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 812px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        margin-top:30%;
        height:40%;
        margin-bottom:0;
        display:block;
        width:108.9%;

        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 850px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        margin-top:50%;
        height:40%;
        margin-bottom:0;
        display:block;
        width:100%;
        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 1400px) {
    .footer-container{
        background-color: #aba8a8;
        position: relative;
        top:-50;
        height:40%;
        margin-top:39%;
        margin-bottom:0;
        display:block;
        width:108.9%;
        justify-content:space-around;
        /* border:5px solid rgb(115, 0, 128); */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
    .icon{
        align-items:center;
        margin:auto;
        width:40%;
        font-size:12px;
        text-align:center
    
    }
    .icons{
        margin:auto;
        font-size:12px;
        color:blue
    
    }
}

body{
    /* background-image: url('../Assets/body-img1.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.nav-about{
    /* background-image: url('../Assets/nav-img5.jpg'); */
 /* height:60%; */
    border: 1px solid rgb(210, 207, 207);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:90%
    /* filter: blur(8px) */
    
}
.community{
    width:93%;
    margin:15% 6%;
    border-radius: 7px;
}
.aboutus1{
margin: auto;
padding:2%;
width:85%;
background-color:rgb(207, 205, 194);
border-radius:10px;
box-shadow:2px 2px rgb(161, 156, 156)
}
li{
    /* line-height:2; */
    font-size: 19px;
}
.trustees{
margin: 2% auto;
padding:6%;
width:85%;
background-color:rgb(206, 197, 202);
border-radius:10px;
box-shadow:2px 2px rgb(161, 156, 156);
height:10%;
}
.proverb{
    text-align:left;
    margin-bottom:3%;
    font-size: 16px;
    color:rgb(35, 88, 88)
}
h1{
    text-align:center;
    margin-bottom:3%;
    font-size: 32px;
}
.mypix{
    width:280px;
    height:230px;
    border-radius:50%;
    border:5px solid rgb(170, 163, 123);
    
}
.membergrid{
    border:1px solid rgb(134, 125, 125);
    padding-left: 25%;
    padding-top: 10%;
    border-radius:5px;
    box-shadow:2px 2px rgb(134, 125, 125);
    background-color:rgb(207, 205, 194)
    
}
.mypix1{
    width:150px;
    height:100px;
    border-radius:50%;
}
.nametag{
    /* border:5px solid; */
    text-align:center;
    width:70%;
    font-family:cursive;
    color:rgb(114, 48, 23)

}
@media only screen and (max-width: 570px) {
    .mypix{
        width:200px;
        height:200px;
        border-radius:50%;
        border:5px solid rgb(170, 163, 123);
    }
}

@media only screen and (max-width: 600px) {
    .community{
        width:93%;
        margin:10% 6%;
        border-radius: 7px; 
    }
    .nametag{
        /* border:1px solid; */
        text-align:center;
        width:70%;
        margin:10% 10%;
        font-size: 16px;
        font-weight:bold;
        font-family:'Courier New', Courier, monospace
    }
}
@media only screen and (max-width: 640px) {
    .community{
        width:90%;
        margin:8% 6%;
        border-radius: 7px;
    } 
    .nametag{
        /* border:1px solid; */
        text-align:center;
        width:70%;
        font-size: 16px;
        font-weight:bold;
        font-family:cursive

    }

}
@media only screen and (max-width: 670px) {
    .mypix{
        width:220px;
        height:220px;
        border-radius:50%;
        border:5px solid rgb(170, 163, 123);
    margin-left: 2%;

    }
}

@media only screen and (max-width: 720px) {
.mypix{
    /* margin-left: 20%; */
}
}





body{
    box-sizing: border-box;
}


.humanservices{
    margin:auto;
    border-radius: 7px;
    width:90%;
    margin-bottom:5%
}
.vid1{
    width:85%;
    margin:0 6%;
    border-radius: 10px;
}
.vid-text{
width:90%

}
h2{
    color:rgb(35, 88, 88);
    font-size:16px
}
h3{
    font-family: cursive;
    text-align: center;
    margin: 20% auto;
    font-size:18px
}
.activities{
    margin: 2% auto;
    padding:6%;
    width:85%;
    background-color:rgb(176, 175, 165);
    border-radius:10px;
    box-shadow:2px 2px rgb(161, 156, 156);
    height:10%;
    }
@media only screen and (max-width: 600px) {
    body{
        width:90vw;
        padding:0;
        margin:0;
        box-shadow: border-box;
    }
    .humanservices{
        margin:auto;
        border-radius: 7px;
        width:90%;
        margin-bottom:5%
    }
    .vid1{
        width:20px;
    margin:10% auto;
    /* border:7px solid */
    }
    .vid-cont{
        /* border:15px solid red; */
        width:280px;
        margin:auto;

    }
    .vid-cont iframe{
   width:270px
    }
}



body{
    width:100%;
    box-sizing: border-box
}
.res-img{
    /* background-image: url('../Assets/lioninthewild.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:20%;
    width:99%;
    border:5px solid;
    margin:0 auto
}
.img-const{
    /* border:7px solid red; */
    padding:0 auto;
    height:40%;

}
.res-sec{
    
    background-color:rgb(191, 181, 144);
    height:70%;
    border:1px solid rgb(191, 181, 144);
    padding-top:5%;
    margin-bottom:5%
}
.resources{
    margin:auto;
    border-radius: 7px;
    width:90%;
    margin-bottom:5%;
    /* border:5px solid; */
}
.res-grid{
    display:flex;
    justify-content:space-between;
    /* border:1px solid red; */
    padding:10% auto;
    height:50%;
    
}
.const-grid{
    background-color:rgb(207, 205, 194);
    width:30%;
   border-radius: 12px;
    

}
.const-grid2{
    background-color:rgb(207, 205, 194);
    width:30%;
   border-radius: 12px;
    

}
.text-div{
    /* border:1px solid; */
    padding:5% 10%
}
.text-div2{
    /* border:1px solid; */
    padding:5% 10%;
    text-align:center;
}
.img-const{
    margin-left:15%;
    margin-top:10%;
    width:68%;
    border-radius:7px;
    outline:5px solid rgb(180, 162, 64)
}
.picnic-grid{
    margin-bottom:10%;
}
.resgrid{
    border:1px solid rgb(134, 125, 125);
    padding-left: 25%;
    padding-top: 7%;
    border-radius:5px;
    box-shadow:2px 2px rgb(134, 125, 125);
    background-color:rgb(207, 205, 194)
    
}
.mypix2{
    width:70%;
    height:50%;
    border-radius:7%;
}
.button-div{
    /* border:1px solid black; */
    display: flex;
    justify-content:center;
    height:70px;
    border-radius:7px;
}
button{
    background-color:rgb(114, 48, 23);
    color:white;
    height:70px;
    border-radius:7px;

}

@media only screen and (max-width: 600px) {
.res-sec{
    margin-top:15%
}
.res-grid{
    display:block;
    width:100%;
}
    .const-grid{
        width:100%;
    }
    .const-grid2{
        width:100%;
        margin-top:5%;
    }
    .middle-div{
        margin-top:5%;
        border:1px solid 
    }
}

.error h2{
    padding-top:10%;
    text-align:center;
    color:red;
    font-size:2rem;
    font-family:cursive;
}
.container{
    display:flex;
justify-content:space-between;
}
.contact-det{
    text-align:center;
    padding: 5%;
}
h2, h3{
    margin-bottom:20px

}
.phone-grid{
padding:30px;
width:60%;

}
.phone{
    height:80%;
    width:60%;
    border-radius:7px;
}
.icon{
    position: relative;
    top:16px;
}

.contact-inv{
    font-size: 1.5rem;
    color:rgb(67, 106, 67);

}

.email-inv{
    color:rgb(114, 48, 23);
    font-size: 1.5rem;
}
.email{
    text-decoration: none;
    background-color:transparent;
    border: none;
    outline: none;
    font-size: 1.3rem;
    color:rgb(89, 0, 255)
}
.email-btn{
    text-decoration: none;
    background-color:transparent;
    border: none;
    outline: none;
    font-size: 1.3rem;
    color:rgb(207, 102, 57)
}

@media only screen and (max-width: 640px) {
    .container{
        /* padding:20px; */
        /* border:1px solid; */
        margin-top:20%
    }
    .contact-inv{
        font-size:16px;
        /* border:1px solid red; */
        margin-right:5%
    }
    .phone-grid{
        padding:0
    }
    .contact-det {
        text-align: center;
        padding: 3%;
    }
    .h3 {
        font-family: cursive;
        text-align: center;
        margin: 20% auto;
        font-size: 16px;
    }
    .email{
        font-size:16px;
    }
    .email-inv{
        font-size:14px;
    }
    .email-btn{
        font-size:16px;
    }
}
@media only screen and (max-width: 812px) {
 .phone-grid{
    display: none;
 }
}


/* @media only screen and (max-width: 640px) {
    body{
      box-sizing: border-box;
    }
    .container{
        display:flex;
    justify-content:space-between;
    }
    .contact{
        text-align:center;
        margin-top:20%
    }

    .contact-inv{
        font-size: 16px;
        margin-left: 0;

    }
    
    .email-inv{
        color:rgb(114, 48, 23);
        font-size: 1.5rem;
    }
} */


body{
  background-color:rgb(214, 209, 209)
}
