body{
    box-sizing: border-box;
    /* top:0 */
}
.footer-container{
    background-color: #aba8a8;
    margin-bottom:0;
    /* width: 120%;? */
    
    
    display:flex;
    justify-content:space-around;
}

.icon{
    align-items:center;
}
.icons{
    margin-left:20%
}
.email{
    color: black;
    text-align:center
}
@media only screen and (max-width: 590px) {
    .footer-container{
        background-color: #aba8a8;
        margin:0% 0%;
        Padding:10% 0%;
        height:40%;
        display:block;
        width:120%;
        justify-content:space-around;
        /* border:1px solid rgb(128, 87, 0); */
        overflow:hidden;
    
    }
}
@media only screen and (max-width: 600px) {
.footer-container{
    background-color: #aba8a8;
    margin:0% 0%;
    Padding:10% 0%;
    height:40%;
    display:block;
    width:100%;
    justify-content:space-around;
    /* border:1px solid green; */
    overflow:hidden;

}
.icon{
    align-items:center;
    margin:auto;
    width:40%;
    font-size:12px;
    text-align:center

}
.icons{
    margin:auto;
    font-size:12px;
    color:blue

}
.email h4{
    text-align:center;
    font-size:10px;
    color:black;

}
}
@media only screen and (max-width: 680px) {
    .footer-container{
        background-color: #aba8a8;
        position: relative;
        top:-20;
        height:40%;
        margin-bottom:0;
        display:block;
        width:99%;
        justify-content:space-around;
        /* border:1px solid rgb(115, 0, 128); */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 800px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        height:40%;
        margin-bottom:0;
        display:block;
        width:103%;
        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 812px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        margin-top:30%;
        height:40%;
        margin-bottom:0;
        display:block;
        width:108.9%;

        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 850px) {
    .footer-container{
        background-color: #aba8a8;
        /* margin:0% 0%; */
        /* Padding: 5% 0%; */
        position: relative;
        top:-20;
        margin-top:50%;
        height:40%;
        margin-bottom:0;
        display:block;
        width:100%;
        justify-content:space-around;
        /* border:1px solid green; */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
}
@media only screen and (max-width: 1400px) {
    .footer-container{
        background-color: #aba8a8;
        position: relative;
        top:-50;
        height:40%;
        margin-top:39%;
        margin-bottom:0;
        display:block;
        width:108.9%;
        justify-content:space-around;
        /* border:5px solid rgb(115, 0, 128); */
        overflow:hidden;
    
    }
    .email h4{
        text-align:center;
        font-size:10px;
        color:black;
    }
    .icon{
        align-items:center;
        margin:auto;
        width:40%;
        font-size:12px;
        text-align:center
    
    }
    .icons{
        margin:auto;
        font-size:12px;
        color:blue
    
    }
}
